import { ReactNode } from "react";
import { cn } from "../../@/lib/utils";

interface AnimatedGradientTextProps {
  children: ReactNode;
  className?: string;
  fromColor?: string;
  viaColor?: string;
  toColor?: string;
}

export default function AnimatedGradientText({
  children,
  className,
  fromColor = "#ffaa40",
  viaColor = "#9c40ff",
  toColor = "#ffaa40",
}: AnimatedGradientTextProps) {
  return (
    <div
      className={cn(
        "group relative mx-auto flex max-w-fit flex-row items-center justify-center rounded-2xl bg-black px-4 py-1.5 text-sm font-medium shadow-[inset_0_-8px_10px_#8fdfff1f] backdrop-blur-sm transition-shadow duration-500 ease-out [--bg-size:300%] hover:shadow-[inset_0_-5px_10px_#8fdfff3f] dark:bg-black/40",
        className,
      )}
    >
      <div
        className={`absolute inset-0 block h-full w-full animate-gradient bg-[length:var(--bg-size)_100%] p-[1px] ![mask-composite:subtract] [border-radius:inherit] [mask:linear-gradient(#fff_0_0)_content-box,linear-gradient(#fff_0_0)]`}
        style={{
          backgroundImage: `linear-gradient(to right, ${fromColor}, ${viaColor}, ${toColor})`,
        }}
      />
      <div className="relative z-10">
        {children}
      </div>
    </div>
  );
}